import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  volunteerFilter: {},
  dashboardFilter: {},
  activePacketFilter: {},
  unAssignedPacketFilter: {},
  completedPacketFilter: {},
};

const { reducer, actions } = createSlice({
  name: "filters",
  initialState,
  reducers: {
    resetFilters: () => {
      return initialState;
    },
    updateVolunteerFilter: (state, action) => {
      state.volunteerFilter = action.payload;
    },
    updateDashboardFilter: (state, action) => {
      state.dashboardFilter = action.payload;
    },
    updateActivePacketFilter: (state, action) => {
      state.activePacketFilter = action.payload;
    },
    updateUnAssignedPacketFilter: (state, action) => {
      state.unAssignedPacketFilter = action.payload;
    },
    updateCompletedPacketFilter: (state, action) => {
      state.completedPacketFilter = action.payload;
    },
  },
});

export const {
  resetFilters,
  updateVolunteerFilter,
  updateDashboardFilter,
  updateActivePacketFilter,
  updateUnAssignedPacketFilter,
  updateCompletedPacketFilter,
} = actions;
export default reducer;
