import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

export const PublicRoute = ({ children }) => {
  const loginData = useSelector((state) => state.loginData.data);
  //
  // if (loginData?.tokens?.accessToken &&
  //     !loginData?.user?.userSettings?.needToChangePassword) {
  //     return <Navigate to="/" replace />;
  // } else if(loginData?.tokens?.accessToken &&
  //     loginData?.user?.userSettings?.needToChangePassword) {
  //     return <Navigate to="/change-password" replace />;
  // }

  return children ? children : <Outlet />;
};

export const PrivateRoute = ({ children }) => {
  const loginData = useSelector((state) => state.loginData.data);

  if (!loginData?.tokens?.accessToken) {
    return <Navigate to="/login" replace />;
  }
  if (
    loginData?.tokens?.accessToken &&
    loginData?.user?.userSettings?.needToChangePassword
  ) {
    return <Navigate to="/change-password" replace />;
  }

  return children;
};
