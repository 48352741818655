import React, { Suspense } from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import "./mainLayout.css";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const MainLayout = () => {
  const location = useLocation();
  return (
    <div id="wrapper">
      <Header />
      <Sidebar />
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner />
          </div>
        }
      >
        <ErrorBoundary
          key={location.pathname}
          fallback={<p>Unable to show the content right now!</p>}
        >
          <Outlet />
        </ErrorBoundary>
      </Suspense>
    </div>
  );
};

export default MainLayout;
