import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";

import loginData from "./reducers/loginData";
import filtersReducer from "./reducers/filters";

const reducers = combineReducers({
  loginData: loginData,
  filters: filtersReducer,
}); // here goes all reducers

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["loginData", "filters"], // here goes those items which you want to store in localstorage
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
