import "bootstrap/dist/css/bootstrap.min.css";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import AppRoutes from "./AppRoutes";
import { Toaster } from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";

function App() {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={() => <p>Unable to show the content right now!</p>}
      >
        <HelmetProvider>
          <BrowserRouter>
            <AppRoutes />
            <Toaster containerStyle={{ zIndex: 99999 }} />
          </BrowserRouter>
        </HelmetProvider>
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
